import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FastForwardRounded } from '@material-ui/icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { c } from '../../utils';
import { ANIM_DURATION } from '../../styles';
import logoSparkle from '../../assets/logo_sparkle.gif';
import { Clickable } from '../../components/Clickable';
import { Button } from '../../components/Button';
import { BuildingContext } from '../../contexts/BuildingContext';
import { TutorialContext } from '../../contexts/TutorialContext';
import classes from './Tutorial.module.scss';
import { TutorialSocket } from './TutorialSocket';
import { STEPS, TutorialState } from './steps';
import { createMockHuddle, createMockUser } from './mocks';
import {
  Action,
  DEFAULT_SPAWNING_POINT,
  getDistance,
  getHuddleRadius,
  IHuddle,
  IUser,
  Point,
} from '@poormanvr/common';

const MainPage = React.lazy(() => import('../MainPage'));

interface Props {
  user: IUser;
  onSubmit: () => void;
}

export function Tutorial({ user, onSubmit }: Props) {
  const { building, floors } = useContext(BuildingContext);
  const { stepIndex, setStepIndex } = useContext(TutorialContext);
  const [socket] = useState(() => new TutorialSocket());
  const [socketOpened, setSocketOpened] = useState(false);

  useEffect(() => {
    const handleOpen = () => {
      setSocketOpened(true);
    };
    const handleClose = () => {
      setSocketOpened(false);
    };
    socket.on('open', handleOpen);
    socket.on('close', handleClose);
    return () => {
      socket.off('open', handleOpen);
      socket.off('close', handleClose);
    };
  }, [socket]);

  const [me, setMe] = useState<IUser>(() => ({
    ...user,
    point: DEFAULT_SPAWNING_POINT,
    active: true,
  }));
  const [them, setThem] = useState<IUser>(() => createMockUser(me));
  const [huddle, setHuddle] = useState<IHuddle | null>(null);

  // TODO: move below logic into a separate reducer
  useEffect(() => {
    const { server } = socket;

    const startMoving = ({ point }: Action.StartMoving) => {
      setMe({ ...me, point, moving: true });
    };
    const stopMoving = () => {
      setMe({ ...me, moving: false });
    };
    const raiseHand = ({ handRaised }: Action.RaiseHand) => {
      setMe({
        ...me,
        handRaised: handRaised ? { raisedAt: Date.now() } : null,
      });
    };
    const changeFloor = ({ floorId }: Action.ChangeFloor) => {
      setMe({ ...me, floorId });
    };
    const lockHuddle = ({ locked }: Action.LockHuddle) => {
      huddle && setHuddle({ ...huddle, locked });
    };

    server.on('START_MOVING', startMoving);
    server.on('STOP_MOVING', stopMoving);
    server.on('RAISE_HAND', raiseHand);
    server.on('CHANGE_FLOOR', changeFloor);
    server.on('LOCK_HUDDLE', lockHuddle);

    return () => {
      server.off('START_MOVING', startMoving);
      server.off('STOP_MOVING', stopMoving);
      server.off('RAISE_HAND', raiseHand);
      server.off('CHANGE_FLOOR', changeFloor);
      server.off('LOCK_HUDDLE', lockHuddle);
    };
  }, [socket, me, them, huddle]);

  useEffect(() => {
    const inHuddle =
      me.floorId === them.floorId &&
      getDistance(me.point, them.point) < getHuddleRadius(2);
    if (!me.moving && inHuddle && !huddle) {
      const newHuddle = createMockHuddle(me, them);
      setHuddle(newHuddle);
      setMe({ ...me, huddleId: newHuddle.id });
      setThem({ ...them, huddleId: newHuddle.id });
    } else if (!inHuddle && huddle) {
      setHuddle(null);
      setMe({ ...me, huddleId: null });
      setThem({ ...them, huddleId: null });
    }
  }, [me, them, huddle]);

  useEffect(() => {
    if (!socketOpened) return;
    socket.receiveSnapshot({
      users: {
        [me.id]: me,
        [them.id]: them,
      },
      broadcasts: {},
      huddles: huddle
        ? {
            [huddle.id]: huddle,
          }
        : {},
      timestamp: Date.now(),
    });
  }, [socket, socketOpened, me, them, huddle]);

  const [point, setPoint] = useState<Point | null>(null);

  const [elevatorOpened, setElevatorOpened] = useState(false);
  const [brochureOpened, setBrochureOpened] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const loop = () => {
      if (!isMounted) return;
      const elevatorOpened = Boolean(document.querySelector('#elevator'));
      const brochureOpened = Boolean(document.querySelector('#brochure'));
      setElevatorOpened(elevatorOpened);
      setBrochureOpened(brochureOpened);
      window.setTimeout(loop, 200);
    };
    loop();
    return () => {
      isMounted = false;
    };
  }, []);

  const destinationFloor = useMemo(
    () =>
      Object.values(floors).find(floor => floor.id !== user.floorId) ?? null,
    [floors, user],
  );

  const tutorialState = useMemo<TutorialState>(
    () => ({
      building,
      floors,
      me,
      them,
      destinationFloor,
      elevatorOpened,
      brochureOpened,
    }),
    [
      building,
      floors,
      me,
      them,
      destinationFloor,
      elevatorOpened,
      brochureOpened,
    ],
  );

  useEffect(() => {
    if (stepIndex < 0) return;
    let newStepIndex = STEPS.findIndex((step, i) => {
      if (i < stepIndex) return false;
      if (!step.done) return true;
      return !step.done(tutorialState);
    });
    if (newStepIndex < 0) {
      setStepIndex(STEPS.length);
      return;
    }
    if (newStepIndex > 0) {
      const step = STEPS[newStepIndex];
      const prevStep = STEPS[newStepIndex - 1];
      if (step.dependent && !prevStep.done?.(tutorialState)) {
        newStepIndex--;
      }
    }
    setStepIndex(newStepIndex);
  }, [stepIndex, tutorialState, setStepIndex]);

  const step = stepIndex < STEPS.length ? STEPS[stepIndex] : null;

  useEffect(() => {
    if (!step) return;
    let isMounted = true;
    const loop = () => {
      if (!isMounted) return;
      const element = document.querySelector(step.selector);
      if (element) {
        const { top, bottom, left, right } = element.getBoundingClientRect();
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const point = step.point
          ? step.point({ top, bottom, left, right, centerX, centerY })
          : { x: centerX, y: centerY };
        setPoint(point);
      }
      window.setTimeout(loop, 200);
    };
    loop();
    return () => {
      isMounted = false;
    };
  }, [stepIndex, step, setStepIndex]);

  const nextStep = useCallback(() => {
    setStepIndex(stepIndex + 1);
  }, [stepIndex]);

  const stepText = useMemo(() => {
    if (!step) return '';
    if (typeof step.text === 'function') {
      return step.text(tutorialState);
    }
    return step.text;
  }, [tutorialState, step]);

  return (
    <div className={classes.Tutorial}>
      <div className={classes.banner}>
        <div className={classes.text}>
          This is a tutorial. Complete the steps to enter the event.
        </div>
        <Button
          className={classes.skip}
          onClick={onSubmit}
          data-cy="skip-tutorial-button"
        >
          <FastForwardRounded className={classes.icon} />
          Skip tutorial
        </Button>
      </div>
      <div className={classes.content}>
        <MainPage className={classes.main} socket={socket} />
        {step && point && (
          <SwitchTransition>
            <CSSTransition
              key={stepIndex}
              timeout={ANIM_DURATION}
              unmountOnExit
              classNames={classes}
            >
              <div
                className={classes.cardWrapper}
                style={{ left: point.x, top: point.y }}
              >
                <div className={c(classes.card, classes[step.cardDirection])}>
                  <div
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: stepText }}
                  />
                  {!step.done && (
                    <Button
                      className={classes.ok}
                      onClick={nextStep}
                      data-cy={step.dataCy}
                    >
                      OK!
                    </Button>
                  )}
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        )}
        {step && point && (
          <div
            className={classes.pointer}
            style={{ left: point.x, top: point.y }}
          />
        )}
        {stepIndex < 0 && (
          <div className={classes.popup}>
            <img className={classes.logo} src={logoSparkle} alt="logo" />
            <div className={classes.welcome}>Welcome {me.name}!</div>
            <div className={classes.description}>
              Click below to start the interactive tutorial that will show you
              how to navigate Gatherly.
            </div>
            <Button
              data-cy="start-tutorial-button"
              className={classes.proceed}
              primary
              onClick={nextStep}
            >
              Start tutorial!
            </Button>
            <Clickable
              data-cy="skip-tutorial-small-button"
              className={classes.skip}
              onClick={onSubmit}
            >
              Skip tutorial
            </Clickable>
          </div>
        )}
        {stepIndex === STEPS.length && (
          <div className={classes.popup}>
            <img className={classes.logo} src={logoSparkle} alt="logo" />
            <div className={classes.description}>
              You've completed the tutorial. Click below to enter the real
              event!
            </div>
            <Button
              data-cy="finish-tutorial-button"
              className={classes.proceed}
              primary
              onClick={onSubmit}
            >
              Let's go!
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
