import { BroadcastScope, ChatScope, Point, StartingSpeakerType } from '..';

export enum Type {
  START_MOVING,
  STOP_MOVING,
  RAISE_HAND,
  CHANGE_FLOOR,
  SEND_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  SEND_MESSAGE,
  DELETE_MESSAGE,
  LOCK_HUDDLE,
  START_BROADCAST,
  STOP_BROADCAST,
  ADD_TO_BROADCAST,
  REMOVE_FROM_BROADCAST,
  BAN_USER,
  UNBAN_USER,
  PING,
  AUDIO_MUTED_BY_PARTICIPANT,
}

export interface Mapping extends Record<Type, any> {
  [Type.START_MOVING]: StartMoving;
  [Type.STOP_MOVING]: StopMoving;
  [Type.RAISE_HAND]: RaiseHand;
  [Type.CHANGE_FLOOR]: ChangeFloor;
  [Type.SEND_ANNOUNCEMENT]: SendAnnouncement;
  [Type.DELETE_ANNOUNCEMENT]: DeleteAnnouncement;
  [Type.SEND_MESSAGE]: SendMessage;
  [Type.DELETE_MESSAGE]: DeleteMessage;
  [Type.LOCK_HUDDLE]: LockHuddle;
  [Type.START_BROADCAST]: StartBroadcast;
  [Type.STOP_BROADCAST]: StopBroadcast;
  [Type.ADD_TO_BROADCAST]: AddToBroadcast;
  [Type.REMOVE_FROM_BROADCAST]: RemoveFromBroadcast;
  [Type.BAN_USER]: BanUser;
  [Type.UNBAN_USER]: UnbanUser;
  [Type.PING]: Ping;
  [Type.AUDIO_MUTED_BY_PARTICIPANT]: MuteUser;
}

export interface StartMoving {
  point: Point;
}

export interface StopMoving {}

export interface RaiseHand {
  handRaised: boolean;
}

export interface ChangeFloor {
  floorId: string;
}

export interface SendAnnouncement {
  body: string;
}

export interface DeleteAnnouncement {
  id: string;
}

export interface SendMessage {
  scope: ChatScope;
  targetId: string;
  body: string;
}

export interface DeleteMessage {
  id: string;
  userId: string;
  targetId?: string;
  scope?: ChatScope;
}

export interface LockHuddle {
  locked: boolean;
}

export interface StartBroadcast {
  scope: BroadcastScope;
  speakerType: StartingSpeakerType;
  countdownTime: number;
}

export interface StopBroadcast {}

export interface AddToBroadcast {
  speakerId: string;
}

export interface RemoveFromBroadcast {
  speakerId: string;
}

export interface BanUser {
  userId: string;
}

export interface MuteUser {
  userId: string;
}

export interface UnbanUser {
  userId: string;
}
export interface Ping {
  start: number;
}
