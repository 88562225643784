import React, { useState } from 'react';
import { getDateTimeFromUnixTimestamp, getTimeZone } from '../../utils';
import { Calendar } from './Calendar';
import classes from './Title.module.scss';

interface Props {
  startTime: number;
  stopTime: number;
  name: string;
}

export const Title: React.FC<Props> = ({ name, startTime, stopTime }) => {
  const [showMore, setShowMore] = useState(false);
  const longName = name.length >= 80;

  return (
    <div className={classes.row}>
      <div>
        <Calendar startTime={startTime} />
      </div>
      <div className={classes.item}>
        <p className={`${classes.time} subtitle2`}>
          {getDateTimeFromUnixTimestamp(startTime) +
            ' - ' +
            getDateTimeFromUnixTimestamp(stopTime) +
            ' ' +
            getTimeZone()}
        </p>
        <h4>{showMore || !longName ? name : name.slice(0, 79) + ' ...'}</h4>
        {longName && (
          <p
            className={`${classes.showMore} body1`}
            onClick={() => {
              setShowMore(!showMore);
            }}
            tabIndex={0}
            role="button"
          >
            {showMore ? 'show less' : 'show more'}
          </p>
        )}
      </div>
    </div>
  );
};
