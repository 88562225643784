import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import classes from './SocialFooter.module.scss';

export const SocialFooter: React.FC = () => {
  const url = window.location.href;

  return (
    <>
      <p className="subtitle2">Share this Event on Social Media:</p>

      <div className={classes.icons}>
        <FacebookShareButton url={url}>
          <FacebookIcon className={classes.icon} />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon className={classes.icon} />
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
          <LinkedInIcon className={classes.icon} />
        </LinkedinShareButton>
      </div>
    </>
  );
};
