import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { EventStatus } from '../../types/eventStatus';
import { getEventStatus } from '../../utils';
import { Countdown } from './Countdown';
import { LiveEvent } from './LiveEvent';
import { EventOver } from './EventOver';
import classes from './InfoCard.module.scss';

interface Props {
  stopTime: number;
  startTime: number;
}

export function InfoCard({ startTime, stopTime }: Props) {
  const [status, setStatus] = useState<EventStatus>(
    getEventStatus(startTime, stopTime),
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        {status === EventStatus.FUTURE && (
          <Countdown startTime={startTime} setStatus={setStatus} />
        )}
        {status === EventStatus.CURRENT && <LiveEvent />}
        {status === EventStatus.PAST && <EventOver stopTime={stopTime} />}
      </CardContent>
    </Card>
  );
}
