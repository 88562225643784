import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import { EventStatus } from '../../types/eventStatus';
import classes from './Countdown.module.scss';
import {
  daysCountdown,
  hoursCountdown,
  minutesCountdown,
  secondsCountdown,
} from '@poormanvr/common';

interface Props {
  startTime: number;
  setStatus: React.Dispatch<React.SetStateAction<EventStatus>>;
}

export const Countdown: React.FC<Props> = ({ startTime, setStatus }) => {
  const secondsUntil = Math.floor((startTime - Date.now()) / 1000);
  const [timer, setTimer] = useState(secondsUntil);
  const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null);

  const clear = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }, [intervalId]);

  useEffect(() => {
    setIntervalId(
      setInterval(() => {
        setTimer(time => time - 1);
      }, 1000),
    );
    return clear;
  }, []);

  /**
   * Reloads the page when the countdown reaches 0 to redirect to the live event
   */
  useEffect(() => {
    if (timer === 0) {
      setStatus(EventStatus.CURRENT);
      clear();
    }
  }, [timer]);

  const largeCountdown = (
    <div className={classes.largeCountDown}>
      <p className="subtitle2">Event Countdown</p>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.countGrid}
      >
        <Grid item>
          <div className={classes.countBox}>{daysCountdown(timer)}</div>
          <p className="body1">Days</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{hoursCountdown(timer)}</div>
          <p className="body1">Hours</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{minutesCountdown(timer)}</div>
          <p className="body1">Minutes</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBox}>{secondsCountdown(timer)}</div>
          <p className="body1">Seconds</p>
        </Grid>
      </Grid>
    </div>
  );

  const smallCountdown = (
    <div className={classes.smallCountDown}>
      <p className="subtitle2">Event Countdown</p>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.countGridSmall}
      >
        <Grid item>
          <div className={classes.countBoxSmall}>{daysCountdown(timer)}</div>
          <p className="body1">Days</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{hoursCountdown(timer)}</div>
          <p className="body1">Hrs</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{minutesCountdown(timer)}</div>
          <p className="body1">Mins</p>
        </Grid>
        <div className={classes.separator}>&nbsp;:&nbsp;</div>
        <Grid item>
          <div className={classes.countBoxSmall}>{secondsCountdown(timer)}</div>
          <p className="body1">Secs</p>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      {smallCountdown}
      {largeCountdown}
    </>
  );
};
