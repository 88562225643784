import { MINUTE } from '@poormanvr/common';

export { default as GATHERLY_LOGO_URL } from './assets/logo_narrow.svg';

export const VIDEO_IDEAL_ASPECT_RATIO = 1.4;
export const VIDEO_MAX_ASPECT_RATIO = 1.6;

export const TAG_COLORS: { [tag: string]: string } = {
  Networking: '#03CAA5',
  Fun: '#7E87D9',
  Social: '#00A9F2',
  Booths: '#ECD189',
};

const [subdomain] = window.location.hostname.split('.');
export const EXIT_URL = `https://gatherly.io/feedback?utm_source=gatherly_event&utm_medium=exit_modal&utm_campaign=${subdomain}`;

export const TECH_SPEC_URL =
  'https://help.gatherly.io/en/articles/4665224-technical-specifications-for-gatherly';

export const EMOJIS = ['👍', '👎', '❤️', '🎉'];

export const MOCK_VIDEO =
  'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif';

export const SENTRY_DSN =
  'https://f9ab4e73e096491e833c96dd604dd603@o445939.ingest.sentry.io/5551502';

export const DEFAULT_BROCHURE_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/brochure-pdf/default-brochure.pdf';

export const IS_LOCAL_DEV = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';

export const TECH_SPECS_URL =
  'https://help.gatherly.io/en/articles/4665224-technical-specifications-for-gatherly';

export const SMARTLOOK_KEY = '9274bba2afd0263e7c15f1aeceefd0be7993f955';

export const CHECKING_HUB_URL = 'https://check.gatherly.io';

export const CHECKING_HUB_ENABLED =
  process.env.REACT_APP_CHECKING_HUB_ENABLED === 'true' ||
  window?.location?.origin === CHECKING_HUB_URL;

export const EVENT_COUNTDOWN_DURATION = 5 * MINUTE;
