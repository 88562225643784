import React from 'react';
import { Button } from '../../components/Button';
import classes from './LiveEvent.module.scss';

export const LiveEvent: React.FC = () => {
  const url = window.location.href;

  return (
    <>
      <p className="body1">This event is live!</p>
      <div className={classes.spacing}>
        <Button
          color="primary"
          className={classes.btn}
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          <h4 className={classes.h4}>
            {' '}
            Join Now
            <span className={classes.arrow}>&#x2192;</span>
          </h4>
        </Button>
      </div>
    </>
  );
};
