import smartlookClient from 'smartlook-client';
import {
  IS_LOCAL_DEV,
  IS_TEST,
  SMARTLOOK_KEY,
  CHECKING_HUB_ENABLED,
} from '../config';
import { TrackerEvent, IBuilding, IUser } from '@poormanvr/common';

export function isTrackingDisabled() {
  if (!SMARTLOOK_KEY) return true;
  if (CHECKING_HUB_ENABLED) return true;
  return IS_TEST || IS_LOCAL_DEV;
}

export function initialize() {
  if (!SMARTLOOK_KEY) {
    console.warn('[EventTracker] not initialized - key missing.');
    return;
  }
  if (isTrackingDisabled()) return;
  try {
    smartlookClient.init(SMARTLOOK_KEY);
    console.debug('[EventTracker] initialized.');
  } catch (e) {
    console.error('[EventTracker] initialzation failed', e);
  }
}

export function test() {
  trackEvent(TrackerEvent.Type.TEST_TRACKER, { value: 'foo' });
}

export function identify(user: IUser | null, building: IBuilding | null) {
  if (isTrackingDisabled()) return;
  if (!user?.id) {
    console.warn('[EventTracker] identification failed - user missing.');
    return;
  }
  try {
    const role = IS_LOCAL_DEV ? 'developer' : user.admin ? 'admin' : 'attendee';
    const anonymousIdentifier = `${role}-${user.id}`;
    smartlookClient.identify(user.id, {
      name: anonymousIdentifier,
      buildingId: building?.id || '',
    });
    console.debug('[EventTracker] identify', anonymousIdentifier, building?.id);
  } catch (e) {
    console.error('[EventTracker] identifcation failed', e);
  }
}

export function anonymize() {
  if (isTrackingDisabled()) return;
  try {
    smartlookClient.anonymize();
  } catch (e) {
    console.error('[EventTracker] anonymize failed', e);
  }
}

export function disable() {
  if (isTrackingDisabled()) return;
  try {
    if (isTrackingDisabled()) return;
    console.debug('[EventTracker] disabled.');
  } catch (e) {
    console.error('[EventTracker] disable failed', e);
  }
}

export function trackEvent<
  T extends TrackerEvent.Type,
  O extends TrackerEvent.Options[T]
>(type: T, options: O) {
  if (isTrackingDisabled()) return;
  try {
    smartlookClient.track(type, options);
    console.debug('[EventTracker] track', type, options);
  } catch (e) {
    console.error(`[EventTracker] trackEvent failed (name=${type})`, e);
  }
}
