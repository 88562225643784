import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { DEFAULT_BANNER_URL, delay, c } from '../../utils';
import { Loading } from '../../components/Loading';
import { EventDescription } from '../../components/EventDescription';
import { SocialFooter } from '../../components/SocialFooter';
import { SponsorLogos } from '../../components/SponsorLogos';
import { InfoCard } from '../../components/InfoCard';
import { BuildingContext } from '../../contexts/BuildingContext';
import classes from './LandingPage.module.scss';

export const LandingPage: React.FC = () => {
  const { eventInfo, eventStatus } = useContext(BuildingContext);

  const [imagesLoading, setImagesLoading] = useState(true);
  useEffect(() => {
    document.title = 'Gatherly | Crafting Engaging Online Events';
  }, []);

  useEffect(() => {
    delay(2000).then(() => {
      setImagesLoading(false);
    });
    return () => {};
  }, []);

  if (!eventInfo) return null;

  const {
    startTime,
    stopTime,
    capacity,
    name,
    userId,
    config: {
      landingPage: { description, logoUrls },
    },
  } = eventInfo;

  const bannerUrl =
    eventInfo.config.landingPage.bannerUrl === ''
      ? DEFAULT_BANNER_URL
      : eventInfo.config.landingPage.bannerUrl;

  return (
    <div className={`landing ${classes.landingContainer}`}>
      <div className={classes.bannerContainer}>
        <img className={classes.banner} src={bannerUrl} alt="banner" />
      </div>
      <div className={classes.container}>
        <div className={classes.largeScreen}>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <div className={classes.lowGrid}>
                <EventDescription
                  startTime={startTime}
                  stopTime={stopTime}
                  capacity={capacity}
                  description={description}
                  name={name}
                  userId={userId}
                />
                {!eventInfo.config.landingPage.socialDisabled && (
                  <div className={classes.social}>
                    <SocialFooter />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.highGrid}>
                <div className={classes.center}>
                  <InfoCard startTime={startTime} stopTime={stopTime} />
                </div>
                <div className={classes.logos}>
                  {logoUrls.length > 0 && <SponsorLogos logoUrls={logoUrls} />}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={c(classes.highGrid, classes.smallScreen)}>
          <div className={classes.center}>
            <InfoCard startTime={startTime} stopTime={stopTime} />
          </div>
          <EventDescription
            startTime={startTime}
            stopTime={stopTime}
            capacity={capacity}
            description={description}
            name={name}
            userId={userId}
          />
          <div className={classes.logos}>
            {logoUrls.length > 0 && <SponsorLogos logoUrls={logoUrls} />}
          </div>
          {!eventInfo.config.landingPage.socialDisabled && (
            <div className={classes.social}>
              <SocialFooter />
            </div>
          )}
        </div>
      </div>
      {imagesLoading && <Loading />}
    </div>
  );
};
