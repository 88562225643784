import React from 'react';
import classes from './SponsorLogos.module.scss';

interface Props {
  logoUrls: string[];
}

export const SponsorLogos: React.FC<Props> = ({ logoUrls }) => (
  <>
    <p className="subtitle2">Brought to you by:</p>
    <div className={classes.logos}>
      {logoUrls.map((url, i) => {
        return <img key={url} src={url} className={classes.image} alt="Logo" />;
      })}
    </div>
  </>
);
