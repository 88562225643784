import React from 'react';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import classes from './Capacity.module.scss';

interface Props {
  capacity: string;
}

export const Capacity: React.FC<Props> = ({ capacity }) => {
  return (
    <div className={classes.capacity}>
      <SupervisedUserCircleIcon className={classes.icon} />
      <p className="body1">{`${capacity} expected attendees`}</p>
    </div>
  );
};
