import {
  IHuddle,
  IUser,
  MAP_HEIGHT,
  MAP_WIDTH,
  takeAveragePoint,
} from '@poormanvr/common';

export function createMockUser(me: IUser, patch: Partial<IUser> = {}): IUser {
  return {
    active: true,
    admin: false,
    allowedFloorIds: null,
    color: null,
    fields: [],
    floorId: me.floorId,
    handRaised: null,
    huddleId: null,
    link: '',
    moving: false,
    name: 'Test',
    point: {
      x: MAP_WIDTH / 2,
      y: MAP_HEIGHT / 2,
    },
    title: '',
    id: '9999',
    email: null,
    banned: false,
    ...patch,
  };
}

export function createMockHuddle(
  me: IUser,
  them: IUser,
  patch: Partial<IHuddle> = {},
): IHuddle {
  return {
    floorId: me.floorId,
    id: '9999',
    locked: false,
    point: takeAveragePoint([me.point, them.point]),
    ...patch,
  };
}
