import React, {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
} from 'react';
import { c } from '../../utils';
import { Tooltip, TooltipProps } from '../Tooltip';
import classes from './Clickable.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  className?: string;
  id?: string;
  tooltipProps?: Omit<TooltipProps, 'label' | 'children'>;
  label?: NonNullable<React.ReactNode>;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  href?: string;
  withoutTooltip?: boolean;
  children?: ReactNode;
}

type BaseElement = HTMLAnchorElement | HTMLButtonElement | HTMLDivElement;

const BaseComponent = forwardRef<BaseElement, any>((props, ref) =>
  props.href ? (
    <a ref={ref} target="_blank" rel="noreferrer" {...props} />
  ) : props.type ? (
    <button ref={ref} {...props} />
  ) : (
    <div role="button" tabIndex={0} ref={ref} {...props} />
  ),
);

export const Clickable = forwardRef<BaseElement, Props>((props, ref) => {
  const {
    className,
    id,
    tooltipProps,
    label,
    onClick,
    disabled,
    href,
    withoutTooltip,
    ...restProps
  } = props;

  const handleKeyDown = useCallback<KeyboardEventHandler>(
    e => {
      switch (e.key) {
        case ' ':
        case 'Enter':
          onClick?.(e as any); // TODO: mock mouseEvent from keyboardEvent?
          break;
        default:
      }
    },
    [onClick],
  );

  const baseComponent = (
    <BaseComponent
      id={id}
      ref={ref}
      className={c(classes.Clickable, disabled && classes.disabled, className)}
      aria-disabled={disabled}
      aria-label={label}
      href={disabled ? undefined : href}
      onClick={disabled ? undefined : onClick}
      onKeyDown={disabled ? undefined : handleKeyDown}
      {...restProps}
    />
  );

  if (withoutTooltip) return baseComponent;

  return (
    <Tooltip label={label} {...(tooltipProps ?? {})}>
      {baseComponent}
    </Tooltip>
  );
});
