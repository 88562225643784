import React from 'react';
import { c } from '../../utils';
import { Clickable } from '../Clickable';
import classes from './Button.module.scss';

interface Props extends React.ComponentProps<typeof Clickable> {
  primary?: boolean;
  negative?: boolean;
}

export function Button({
  className,
  primary,
  negative,
  disabled,
  ...restProps
}: Props) {
  return (
    <Clickable
      className={c(
        classes.Button,
        primary && classes.primary,
        negative && classes.negative,
        disabled && classes.disabled,
        className,
      )}
      disabled={disabled}
      {...restProps}
    />
  );
}
