import React, { useEffect, useState } from 'react';
import { VideocamRounded } from '@material-ui/icons';
import logoWalk from '../../assets/logo_walk.gif';
import { Button } from '../../components/Button';
import classes from './PermissionSetup.module.scss';

interface Props {
  onSubmit: () => void;
}

export function PermissionSetup({ onSubmit }: Props) {
  const [skippable, setSkippable] = useState(false);

  useEffect(() => {
    const handle = window.setTimeout(() => {
      setSkippable(true);
    }, 3000);
    return () => {
      window.clearTimeout(handle);
    };
  }, []);

  useEffect(() => {
    const requestPermission = () => {
      return window.setTimeout(() => {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then(() => onSubmit())
          .catch(e => {
            console.error(e);
            if (
              ['NotAllowedError', 'PermissionDismissedError'].includes(e.name)
            ) {
              handle = requestPermission();
            } else {
              window.alert(e);
              onSubmit();
            }
          });
      }, 1000);
    };
    let handle = requestPermission();
    return () => {
      window.clearTimeout(handle);
    };
  }, [onSubmit]);

  const label = 'microphone and camera';

  return (
    <div className={classes.PermissionSetup}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <img src={logoWalk} />
        </div>
        <div className={classes.instructions}>Allow your {label}</div>
        <div className={classes.form}>
          <div className={classes.description}>
            Please allow access to your {label} through the popup on the top
            left side of your window.
            <br />
            <br />
            If you don’t see the pop-up, please click the{' '}
            <VideocamRounded className={classes.icon} /> icon in the top right
            corner at the end of your search bar and change the setting to
            always allow access.
          </div>
          <Button
            className={classes.skip}
            disabled={!skippable}
            onClick={onSubmit}
          >
            Proceed without them
          </Button>
        </div>
      </div>
    </div>
  );
}
