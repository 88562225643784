import React, { useState } from 'react';
import { formatDate } from '../../utils';
import classes from './Buttons.module.scss';

interface Props {
  startTime: number;
  stopTime: number;
  name: string;
  description: string;
  userId: string;
}

export const Buttons: React.FC<Props> = ({
  name,
  startTime,
  stopTime,
  description,
  userId,
}) => {
  const [email, setEmail] = useState('');
  const url = window.location.href;
  // eslint-disable-next-line new-cap
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.spacing}>
        <div
          title="Add to Calendar"
          className={`${classes.addeventatc} addeventatc`}
        >
          Add to Calendar
          <span className="start">{formatDate(startTime)}</span>
          <span className="end">{formatDate(stopTime)}</span>
          <span className="timezone">{timeZone}</span>
          <span className="title">{name}</span>
          <span className="description">{description}</span>
          <span className="location">{url}</span>
          <span className="addeventatc_icon atc_node notranslate" />
        </div>
      </div>
      <div>
        <a className={classes.emailBtn} href={`mailto:${email}`}>
          Email the Host
        </a>
      </div>
    </div>
  );
};
