import React, {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import { CHECKING_HUB_ENABLED } from '../config';
import { useRequest } from '../hooks/useRequest';
import {
  getUserParams,
  handleRequestError,
  EventTracker,
  getEventStatus,
} from '../utils';
import { GetNearestChimeMediaRegion } from '../requests';
import { EventStatus } from '../types/eventStatus';

import {
  Dictionary,
  HttpMethod,
  IBuilding,
  IFloor,
  Request,
  EmailSettings,
  BetterDmvEvent,
} from '@poormanvr/common';

interface Value {
  building: IBuilding | null;
  floors: Dictionary<IFloor>;
  myId: string | null;
  setMyId: Dispatch<string | null>;
  chimeMediaRegion: string | null;
  blankEmailField: '' | null;
  eventInfo: BetterDmvEvent | null;
  eventStatus: EventStatus | null;
}

export const BuildingContext = createContext<Value>(null as any);

interface Props {
  children: ReactNode;
}

function getEventAlias(): string | null {
  const regExp = /^([\w-]+)\.(event|event-ng)\.gatherly\.io$/i;
  if (!regExp.test(window.location.hostname)) {
    return null;
  }
  return window?.location?.hostname?.match(regExp)?.[1] ?? null;
}
const searchParams = new URLSearchParams(window.location.search);
const queryStringAliasName =
  process.env.NODE_ENV === 'development' ? searchParams.get('aliasName') : null;

const eventAlias = queryStringAliasName || getEventAlias();

export function BuildingProvider({ children }: Props) {
  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [floors, setFloors] = useState<Dictionary<IFloor>>({});
  const [myId, setMyId] = useState<string | null>(null);
  const [chimeMediaRegion, setChimeMediaRegion] = useState<string | null>(null);

  const [isGetEventInfoRequesting, setGetInfoRequesting] = useState<boolean>(
    true,
  );
  const hasEventAlias = eventAlias !== null;
  const [eventInfo, setEventInfo] = useState<BetterDmvEvent | null>(null);
  const eventStatus = useMemo<EventStatus>(() => {
    if (eventAlias === null) {
      return EventStatus.CURRENT;
    }

    if (isGetEventInfoRequesting) {
      return EventStatus.FETCHING;
    }

    if (!eventInfo) {
      return EventStatus.NOT_FOUND;
    }

    return (
      (eventInfo && getEventStatus(eventInfo.startTime, eventInfo.stopTime)) ??
      EventStatus.NOT_FOUND
    );
  }, [eventInfo, isGetEventInfoRequesting]);

  const getEventInfo = useRequest<Request.GetEventInfo>(
    HttpMethod.GET,
    `https://${eventAlias}.server.gatherly.io/api/event-info`,
  );

  const getBuilding = useRequest<Request.GetBuilding>(
    HttpMethod.GET,
    '/api/building',
  );
  const updateUser = useRequest<Request.UpdateUser>(
    HttpMethod.PUT,
    '/api/user',
  );

  const getNearestChimeMediaRegion = useRequest<GetNearestChimeMediaRegion>(
    HttpMethod.GET,
    'https://nearest-media-region.l.chime.aws/',
  );

  useEffect(() => {
    async function checkDynamo() {
      if (CHECKING_HUB_ENABLED) return;

      if (eventAlias !== null) {
        const eventInfo = await getEventInfo().catch(() => null);

        if (eventInfo) {
          setEventInfo(eventInfo);
        }
      }
      setGetInfoRequesting(false);
    }
    let interval: NodeJS.Timeout;
    if (eventAlias !== null) {
      checkDynamo();
      // Updates every 5 minutes in case of change to start time
      interval = setInterval(() => checkDynamo(), 300000);
    } else {
      setGetInfoRequesting(false);
    }
    return () => {
      if (eventAlias !== null) {
        clearInterval(interval);
      }
    };
  }, [eventAlias]);

  useEffect(() => {
    (async () => {
      if (isGetEventInfoRequesting) return;
      if (hasEventAlias && eventStatus !== EventStatus.CURRENT) return;
      if (CHECKING_HUB_ENABLED) return;
      const [buildingResp, regionResp] = await Promise.all([
        getBuilding(),
        getNearestChimeMediaRegion().catch(e => {
          console.error(e);
          return null;
        }),
      ]);
      const { building, floors, user } = buildingResp;
      const chimeMediaRegion = regionResp?.region ?? building.chimeMediaRegion;

      let myId = user?.id ?? null;
      if (user) {
        const userParams = getUserParams(floors, user);

        try {
          await updateUser({
            ...userParams,
            link: user.link,
            email: user.email,
            fields: user.fields,
            chimeMediaRegion,
            href: window.location.href,
          });
        } catch (e) {
          handleRequestError(e);
          myId = null;
        }
      }
      setBuilding(building);
      setFloors(floors);
      setMyId(myId);
      setChimeMediaRegion(chimeMediaRegion);
      EventTracker.identify(user, building);
    })().catch(handleRequestError);
  }, [
    getNearestChimeMediaRegion,
    getBuilding,
    updateUser,
    isGetEventInfoRequesting,
    eventStatus,
    hasEventAlias,
  ]);

  useEffect(() => {
    Sentry.setTag('buildingId', building?.id ?? '');
  }, [building?.id]);

  const blankEmailField =
    building?.emailSettings === EmailSettings.DISABLED ? null : '';

  const value = useMemo<Value>(
    () => ({
      building,
      floors,
      myId,
      setMyId,
      chimeMediaRegion,
      blankEmailField,
      eventStatus,
      eventInfo,
    }),
    [
      building,
      floors,
      myId,
      setMyId,
      chimeMediaRegion,
      blankEmailField,
      eventStatus,
      eventInfo,
    ],
  );

  return (
    <BuildingContext.Provider value={value}>
      {children}
    </BuildingContext.Provider>
  );
}
