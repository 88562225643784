import React, { Suspense, useContext, useMemo, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Loading } from '../Loading';
import { CHECKING_HUB_ENABLED } from '../../config';
import { ANIM_DURATION } from '../../styles';
import { Tutorial } from '../../pages/Tutorial';
import { PermissionSetup } from '../../pages/PermissionSetup';
import { ProfileSetup } from '../../pages/ProfileSetup';
import { BuildingContext } from '../../contexts/BuildingContext';
import { NotSupported } from '../../pages/NotSupported';
import { NoEvent } from '../../pages/NoEvent';
import { LandingPage } from '../../pages/LandingPage';

import { TutorialContext } from '../../contexts/TutorialContext';
import { IS_BROWSER_SUPPORTED, IS_DEVICE_SUPPORTED } from '../../utils/device';
import { EventStatus } from '../../types/eventStatus';
import classes from './App.module.scss';

const MainPage = React.lazy(() => import('../../pages/MainPage'));
const DeviceSetup = React.lazy(() => import('../../pages/DeviceSetup'));
const CheckingHub = React.lazy(() => import('../../pages/CheckingHub'));

export function App() {
  const { myId, building, eventStatus, eventInfo } = useContext(
    BuildingContext,
  );
  const { tutorialUser, setTutorialUser } = useContext(TutorialContext);

  const profileSetup = Boolean(myId);
  const [isSupported, setIsSupported] = useState(
    IS_BROWSER_SUPPORTED && IS_DEVICE_SUPPORTED,
  );
  const [permissionSetup, setPermissionSetup] = useState(false);
  const [deviceSetup, setDeviceSetup] = useState(false);

  const node = useMemo(() => {
    if (eventStatus === EventStatus.FETCHING) {
      return <Loading message="Fetching event info ..." />;
    }

    if (eventStatus === EventStatus.NOT_FOUND) {
      return <NoEvent />;
    }

    if (eventStatus !== EventStatus.CURRENT) {
      return <LandingPage />;
    }

    if (CHECKING_HUB_ENABLED) {
      return <CheckingHub />;
    }
    if (!isSupported) {
      return <NotSupported onSkip={() => setIsSupported(true)} />;
    }
    if (!building) {
      return <Loading message="Fetching building info ..." />;
    }
    if (!profileSetup) {
      return <ProfileSetup />;
    }
    if (!permissionSetup) {
      return (
        <PermissionSetup
          key="device"
          onSubmit={() => setPermissionSetup(true)}
        />
      );
    }
    if (!deviceSetup) {
      return <DeviceSetup key="device" onSubmit={() => setDeviceSetup(true)} />;
    }
    if (tutorialUser) {
      return (
        <Tutorial user={tutorialUser} onSubmit={() => setTutorialUser(null)} />
      );
    }
    return <MainPage />;
  }, [
    isSupported,
    building,
    profileSetup,
    permissionSetup,
    deviceSetup,
    tutorialUser,
    setTutorialUser,
    eventStatus,
    eventInfo,
  ]);

  return (
    <div className={classes.App}>
      <SwitchTransition>
        <CSSTransition
          key={node.key ?? node.type}
          timeout={ANIM_DURATION}
          unmountOnExit
          classNames={classes}
        >
          <Suspense fallback={<Loading message="Loading ..." />}>
            {node}
          </Suspense>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
