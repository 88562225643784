import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { getMonth } from '../../utils';
import classes from './Calendar.module.scss';

interface Props {
  startTime: number;
}

export const Calendar: React.FC<Props> = ({ startTime }) => {
  const date = new Date(startTime);
  const month = getMonth(date).substring(0, 3);
  const day = date.getDate();

  return (
    <Card className={classes.calendar}>
      <CardHeader title={month} className={classes.header} />
      <CardContent className={classes.body}>
        <h4>{day}</h4>
      </CardContent>
    </Card>
  );
};
