import React, { useState } from 'react';
import classes from './Description.module.scss';

interface Props {
  description: string;
}

export const Description: React.FC<Props> = ({ description }) => {
  const [showMore, setShowMore] = useState(false);
  const longDescription = description.length >= 400;

  return (
    <>
      <p className="subtitle2">About this Event:</p>
      <p className="body1">
        {' '}
        {showMore || !longDescription
          ? description
          : description.slice(0, 399) + ' ...'}
      </p>

      {longDescription && (
        <p
          className={`${classes.showMore} body1`}
          tabIndex={0}
          role="button"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {' '}
          {showMore ? 'show less' : 'show more'}
        </p>
      )}
    </>
  );
};
