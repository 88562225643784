import React from 'react';

import { Capacity } from './Capacity';
import { Description } from './Description';
import { Title } from './Title';
import { Buttons } from './Buttons';
import classes from './EventDescription.module.scss';

interface Props {
  startTime: number;
  stopTime: number;
  name: string;
  description: string;
  capacity: string;
  userId: string;
}

export const EventDescription: React.FC<Props> = ({
  startTime,
  stopTime,
  capacity,
  description,
  name,
  userId,
}) => {
  return (
    <>
      <div className={classes.spacing}>
        <Title startTime={startTime} stopTime={stopTime} name={name} />
      </div>
      <div className={classes.spacing}>
        <Buttons
          startTime={startTime}
          stopTime={stopTime}
          name={name}
          description={description}
          userId={userId}
        />
      </div>
      <div className={classes.spacing}>
        <Capacity capacity={capacity} />
      </div>
      {description && (
        <div className={classes.spacing}>
          <Description description={description} />
        </div>
      )}
    </>
  );
};
