import React from 'react';
import { DateTime } from 'luxon';

import classes from './EventOver.module.scss';

interface Props {
  stopTime: number;
}

export const EventOver: React.FC<Props> = ({ stopTime }) => {
  const date = new Date(stopTime);

  return (
    <div className={classes.container}>
      <p className="body1">
        {'This event ended on ' + DateTime.fromJSDate(date).toFormat('MMMM dd')}
      </p>
    </div>
  );
};
